import React from "react"
import { withPrismicUnpublishedPreview, componentResolverFromMap } from "gatsby-plugin-prismic-previews"

import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"
import Article from "../templates/Article"

import { linkResolver } from "src/gatsby/linkResolver"

const NotFoundPage = () => (
  <Layout heroCopy="Not Found">
    <SEO title="404: Not found" />
    <Section my={24}>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Section>
  </Layout>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
